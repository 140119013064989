import { Helmet } from "react-helmet";

interface BaseHelmetProps {
  page: string;
}

const BaseHelmet = ({ page }: BaseHelmetProps) => {
  return (
    <Helmet>
      <title>DNAR DYNASTY | {page}</title>
      <meta name="description" content={`DNAR DYNASTY | ${page}`} />
      <meta
        name="keywords"
        content="safety, bodyguard, solutions, software, property development, construction, management, sales, self improvement, training"
      />
    </Helmet>
  );
};

export default BaseHelmet;
