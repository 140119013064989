import Footer from "./footer/Footer";
import Header from "./header/Header";

const Layout = ({ children }) => {
  return (
    <>
      <main className="main">
        {/*<Header />*/}
        <Header />
        {children}
        <Footer />
      </main>
    </>
  );
};

export default Layout;
