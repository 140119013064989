import useLang from "@/hooks/useLang";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import ResponsiveMenu, { Menus } from "../responsive-menu/ResponsiveMenu";
import NavMenu from "./NavMenu";

const StickyNav = () => {
  const { content } = useLang();
  const menus: Menus = useMemo(
    () => [
      {
        id: 1,
        text: content.nav.home,
        link: "/",
        dropdown: [],
      },
      {
        id: 2,
        text: content.nav.about,
        link: "#about-us",
        dropdown: [],
      },
      {
        id: 3,
        text: content.nav.services.title,
        link: "#",
        dropdown: [
          {
            id: 3.1,
            text: content.nav.services.ddBg,
            link: "/services-1",
          },
          {
            id: 3.2,
            text: content.nav.services.ddSolutions,
            link: "/services-2",
          },
          {
            id: 3.3,
            text: content.nav.services.ddDevelopment,
            link: "/service-details/1",
          },
          {
            id: 3.4,
            text: content.nav.services.ddTraining,
            link: "/service-details/1",
          },
        ],
      },
      {
        id: 4,
        text: content.nav.partners,
        link: "#partners",
        dropdown: [],
      },
      {
        id: 5,
        text: content.nav.contact,
        link: "/contact-us",
        dropdown: [],
      },
    ],
    [content]
  );

  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 100) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  let x = ["mnmenu-sec"];
  if (scrolled) {
    x.push("sticky_menu");
  }
  return (
    <>
      <div className={x.join(" ")}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 nav-menu">
              <div className="col-xs-5 col-sm-4 col-md-3">
                <div className="logo py-2">
                  <Link className="" to={"/"}>
                    <img
                      src={`/logo.png`}
                      className="h-full w-full"
                      alt="Logo"
                    />
                  </Link>
                </div>
              </div>

              <div className="col-xs-7 col-sm-8 col-md-9">
                <div className="responsive-nav-menu">
                  <ResponsiveMenu menus={menus} />
                </div>
                <div className="menu">
                  <nav id="main-menu" className="main-menu">
                    <NavMenu menus={menus} />
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StickyNav;
