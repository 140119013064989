import emailjs from "@emailjs/browser";
import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";

const HomePageForm = () => {
  const [selectedDate, setSelectedDate] = useState();
  const [startDate, setStartDate] = useState();

  const [result, setResult] = useState(false);
  const sendEmail = (e: any) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_as81qqb",
        "template_i7bbp9p",
        e.target,
        "9bbRvbc0Smfv3Y2Iv"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    setResult(true);
  };

  return (
    <>
      <div className="appointment-form xs:!-mt-12 lg:!mt-0">
        <h2>Contact Us</h2>
        <form className="pr-5" action="" onSubmit={sendEmail}>
          <fieldset>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="sngl-field">
                  <input placeholder="Name" name="name" type="text" required />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="sngl-field">
                  <input
                    placeholder="E-mail"
                    name="email"
                    type="email"
                    required
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="sngl-field">
                  <input placeholder="Phone" name="tel" type="text" />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="sngl-field">
                  <input placeholder="Phone" name="tel" type="text" />
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="sngl-field">
                  <textarea placeholder="Message" name="message" />
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="filed-submitbtn">
                  <input value="Submit Enquiry" type="submit" />
                </div>
                <div className="flex flex-col pt-3">
                  <div className="inner-item">
                    <div className="media">
                      <div className="media-left">
                        <span className="icon">
                          <i className="fa fa-envelope-o" />
                        </span>
                      </div>
                      <div className="media-body">
                        <span className="inner-text">
                          customerrelations@dnardynasty.com
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="inner-item">
                  <div className="media">
                    <div className="media-left">
                      <span className="icon">
                        <i className="fa fa-phone" />
                      </span>
                    </div>
                    <div className="media-body">
                      <span className="inner-text">
                        04-2400955
                        {/* +880 256794, 24-2564687 */}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          {result ? (
            <div className="success-send-msg">
              Your message has been sent successfully. We will contact you
              shortly.
            </div>
          ) : null}
        </form>
      </div>
    </>
  );
};

export default HomePageForm;
