export default {
  EN: {
    hero: {
      title:
        "Empowering <span>Security</span> & Technology <span>Solutions<span>",
      desc: "Delivering Excellence in Bodyguard & Security Training, as well as Cutting-Edge Software Solutions.",
      cta1: "Learn More About DD BG",
      cta2: "Explore DD Solutions",
    },
    about: {
      title: "<span>About</span> DNAR DYNASTY",
      desc: `DNAR DYNASTY revolutionizes organizations by offering expertise across four key
        segments: Software Solutions, Training Services, Bodyguard & Security Services, and
        Property Development. Our journey began as a passionate conversation among friends eager
        to assist companies in software, safety, and development. Today, we partner with clients to
        realize their vision and drive businesses forward with tailored solutions and a commitment to
        excellence.`,
    },
    ddBg: {
      title: "DNAR <span>Bodyguard</span> & <span>Security</span> Services",
      desc: "Modern safety demands innovative solutions. DNAR DYNASTY delivers premium security and bodyguard services tailored to individual and institutional needs. Our services include:",
      content1: {
        title: "Static & Unarmed Guard",
        desc: "Professional and vigilant on-site security.",
      },
      content2: {
        title: "Armed Guard",
        desc: "Trained personnel for high-risk environments.",
      },
      content3: {
        title: "Maritime Security",
        desc: "Protection for vessels and offshore assets.",
      },
      content4: {
        title: "Mobile Patrol",
        desc: "Dynamic surveillance and rapid response.",
      },
      content5: {
        title: "Integrated Electronic Security Systems",
        desc: "Advanced technology for comprehensive safety.",
      },
      content6: {
        title: "Event Security",
        desc: "Ensuring safety for private and public events.",
      },
      content7: {
        title: "Close Protection/Bodyguard Services",
        desc: "Discreet and effective personal protection.",
      },
    },
    ddSolutions: {
      title:
        "DNAR <span>Solutions</span> - Channel Partner <span>Software Solutions</span>",
      desc: `Empowering clients with cutting-edge software solutions, DNAR DYNASTY is a trusted
channel partner catering to government, corporate, and academic sectors. Our offerings
include:`,
      content1: {
        title: "Education Software & Content",
        desc: "Tools to enhance digital learning and academic management.",
      },
      content2: {
        title: "Research Software",
        desc: "Solutions designed to streamline academic and industrial research processes",
      },
      content3: {
        title: "Security System & CCTV Integration",
        desc: "Advanced surveillance and monitoring systems.",
      },
      content4: {
        title: "Customized Applications",
        desc: "Tailored software built to meet specific operational needs.",
      },
    },
    ddDevelopment: {
      title: "DNAR <span>Developement</span>",
      desc: "DNAR DYNASTY supports property development projects from inception to completion, offering expertise in construction, procurement, and workforce management. Our end-to-end solutions include:",
      content1: {
        title: "Construction Assistance",
        desc: "From planning to execution.",
      },
      content2: {
        title: "Procurement Management",
        desc: "Efficient sourcing of materials and resources.",
      },
      content3: {
        title: "Workforce & Administration",
        desc: "Skilled labour and administrative support.",
      },
      content4: {
        title: "Sales & Marketing",
        desc: "Strategies to maximize project visibility and profitability.",
      },
    },
    ddTraining: {
      title: "DNAR <span>Training Services</span>",
      desc: "Focusing on safety and self-development, DNAR DYNASTY provides comprehensive training programs led by experienced professionals. Our training services include:",
      content1: {
        title: "Safety Training",
        desc: "Techniques for industrial safety, bodyguard practices, and martial arts.",
      },
      content2: {
        title: "Self-Development",
        desc: "Sales & marketing, mental health, and personal growth programs.",
      },
      content3: {
        title: "Specialized Women's Safety Training",
        desc: "Empowering women with essential safety skills.",
      },
      content4: {
        title: "Collaborative Programs",
        desc: "Partnerships with seasoned trainers for customized workshops.",
      },
    },
    news: {
      title: "<span>News</span> and Insights",
      desc: "Latest news, analysis, and perspectives on current issues.",
      content1: {
        title: "Security Training",
        desc: "-",
      },
      content2: {
        title: "Technology Trends",
        desc: "-",
      },
      content3: {
        title: "Case Studies",
        desc: "-",
      },
    },
    commonText: {
      contact: "Contact Us",
      about: "About Us",
      services: "Services",
      partners: "Partners",
      "link-services": "Service Links",
      "follow-us": "Follow Us",
      "submit-enquiry": "Submit Enquiry",
    },
    nav: {
      home: "Home",
      about: "About Us",
      services: {
        title: "Our Services",
        ddBg: "DD Bodyguard & Security Services",
        ddSolutions: "DD Solutions",
        ddDevelopment: "DD Development",
        ddTraining: "DD Training Services",
      },
      partners: "Partners",
      contact: "Contact Us",
    },
    footer: {
      ddBg: "DD Bodyguard & Security Services",
      ddSolutions: "DD Solutions",
      ddDevelopment: "DD Development",
      ddTraining: "DD Training Services",
      slogan:
        "Let DNAR DYNASTY be your partner in progress. Together, we’ll achieve excellence and transform visions into reality.",
    },
  },
  BM: {
    hero: {
      title:
        "Memperkasa Solusi <span>Keselamatan</span> & <span>Teknologi</span>",
      desc: "Memberikan Kecemerlangan dalam Latihan Bodyguard & Keselamatan serta Solusi Perisian Terkini.",
      cta1: "Ketahui Lebih Lanjut Tentang DD BG",
      cta2: "Terokai DD Solutions",
    },
    about: {
      title: "<span>Tentang</span> DNAR DYNASTY",
      desc: "DNAR DYNASTY merevolusikan organisasi dengan menawarkan kepakaran dalam empat segmen utama: Penyelesaian Perisian, Perkhidmatan Latihan, Perkhidmatan Pengawal Peribadi & Keselamatan, serta Pembangunan Hartanah. Perjalanan kami bermula daripada perbincangan penuh semangat antara rakan-rakan yang ingin membantu syarikat dalam perisian, keselamatan, dan pembangunan. Hari ini, kami bekerjasama dengan pelanggan untuk merealisasikan visi mereka dan memacu perniagaan dengan penyelesaian yang disesuaikan serta komitmen terhadap kecemerlangan.",
    },
    ddBg: {
      title:
        "DNAR Perkhidmatan <span>Bodyguard</span> & <span>Keselamatan</span>",
      desc: "Keperluan keselamatan moden memerlukan penyelesaian inovatif. DNAR DYNASTY menyediakan perkhidmatan keselamatan dan pengawal peribadi premium yang disesuaikan dengan keperluan individu dan institusi. Perkhidmatan kami termasuk:",
      content1: {
        title: "Pengawal Statik & Tanpa Senjata",
        desc: "Keselamatan di tapak yang profesional dan berwaspada.",
      },
      content2: {
        title: "Pengawal Bersenjata",
        desc: "Personel terlatih untuk persekitaran berisiko tinggi.",
      },
      content3: {
        title: "Keselamatan Maritim",
        desc: "Perlindungan untuk kapal dan aset luar pesisir.",
      },
      content4: {
        title: "Rondaan Bergerak",
        desc: "Pengawasan dinamik dan tindak balas pantas.",
      },
      content5: {
        title: "Sistem Keselamatan Elektronik Bersepadu",
        desc: "Teknologi canggih untuk keselamatan menyeluruh.",
      },
      content6: {
        title: "Keselamatan Acara",
        desc: "Memastikan keselamatan untuk acara persendirian dan awam.",
      },
      content7: {
        title: "Perkhidmatan Pengawal Peribadi",
        desc: "Perlindungan peribadi yang cekap dan berdisiplin.",
      },
    },
    ddSolutions: {
      title:
        "DNAR <span>Solutions</span> - Rakan Kongsi <span>Solusi Perisian</span>",
      desc: "Memperkasa pelanggan dengan penyelesaian perisian terkini, DNAR DYNASTY adalah rakan kongsi dipercayai yang memenuhi keperluan sektor kerajaan, korporat, dan akademik. Perkhidmatan kami termasuk:",
      content1: {
        title: "Perisian & Kandungan Pendidikan",
        desc: "Alat untuk meningkatkan pembelajaran digital dan pengurusan akademik.",
      },

      content2: {
        title: "Perisian Penyelidikan",
        desc: "Penyelesaian untuk memperkemas proses penyelidikan akademik dan industri.",
      },

      content3: {
        title: "Integrasi Sistem Keselamatan & CCTV",
        desc: "Sistem pengawasan dan pemantauan yang canggih.",
      },

      content4: {
        title: "Aplikasi Disesuaikan",
        desc: "Perisian yang dibangunkan khas untuk memenuhi keperluan operasi tertentu.",
      },
    },
    ddDevelopment: {
      title: "DNAR <span>Pembangunan</span>",
      desc: "DNAR DYNASTY menyokong projek pembangunan hartanah dari perancangan hingga penyelesaian, dengan kepakaran dalam pembinaan, perolehan, dan pengurusan tenaga kerja. Penyelesaian menyeluruh kami merangkumi:",
      content1: {
        title: "Bantuan Pembinaan",
        desc: "Dari perancangan hingga pelaksanaan.",
      },
      content2: {
        title: "Pengurusan Perolehan",
        desc: "Sumber bahan dan sumber daya yang cekap.",
      },
      content3: {
        title: "Tenaga Kerja & Pentadbiran",
        desc: "Sokongan tenaga kerja mahir dan pentadbiran.",
      },
      content4: {
        title: "Jualan & Pemasaran",
        desc: "Strategi untuk memaksimumkan keterlihatan dan keuntungan projek.",
      },
    },
    ddTraining: {
      title: "DNAR <span>Perkhidmatan Latihan</span>",
      desc: "Dengan fokus pada keselamatan dan pembangunan diri, DNAR DYNASTY menyediakan program latihan komprehensif yang dikendalikan oleh profesional berpengalaman. Perkhidmatan latihan kami merangkumi:",

      content1: {
        title: "Latihan Keselamatan",
        desc: "Teknik untuk keselamatan industri, amalan pengawal peribadi, dan seni mempertahankan diri.",
      },
      content2: {
        title: "Pembangunan Diri",
        desc: "Program jualan & pemasaran, kesihatan mental, dan pertumbuhan peribadi.",
      },
      content3: {
        title: "Latihan Keselamatan Khusus Wanita",
        desc: "Memperkasakan wanita dengan kemahiran keselamatan penting.",
      },
      content4: {
        title: "Program Kolaborasi",
        desc: "Perkongsian dengan jurulatih berpengalaman untuk bengkel khusus.",
      },
    },
    news: {
      title: "<span>Berita</span> & Pandangan",
      desc: "Berita terkini, analisis, dan pandangan isu semasa.",
      content1: {
        title: "Latihan Keselamatan",
        desc: "-",
      },
      content2: {
        title: "Trend Teknologi",
        desc: "-",
      },
      content3: {
        title: "Kes Projek",
        desc: "-",
      },
    },
    commonText: {
      contact: "Hubungi Kami",
      about: "Tentang Kami",
      services: "Perkhidmatan",
      partners: "Rakan Kongsi",
      "link-services": "Link Perkhidmatan",
      "follow-us": "Ikuti Kami",
      "submit-enquiry": "Hantar Pertanyaan",
    },
    nav: {
      home: "Utama",
      about: "Tentang Kami",
      services: {
        title: "Perkhidmatan Kami",
        ddBg: "DD Perkhidmatan Bodyguard & Keselamatan",
        ddSolutions: "DD Solusi",
        ddDevelopment: "DD Pembangunan",
        ddTraining: "DD Perkhidmatan Latihan",
      },
      partners: "Rakan Kongsi",
      contact: "Hubungi Kami",
    },
    footer: {
      ddBg: "DD Perkhidmatan Bodyguard & Keselamatan",
      ddSolutions: "DD Solusi",
      ddDevelopment: "DD Pembangunan",
      ddTraining: "DD Perkhidmatan Latihan",
      slogan:
        "Biarkan DNAR DYNASTY menjadi rakan kongsi anda dalam kemajuan. Bersama-sama, kita akan mencapai kecemerlangan dan mengubah visi menjadi realiti.",
    },
  },
};
