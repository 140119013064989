import useLang from "@/hooks/useLang";
import { useMemo } from "react";
import SectionTitle from "../section-title/SectionTitle";

const DDDevelopment = () => {
  const { content } = useLang();

  const serviceData = useMemo(() => {
    return [
      {
        id: 1,
        icon: "resort.png",
        image: "service_details_1.jpg",
        title: content.ddDevelopment.content1.title,
        excerpt: content.ddDevelopment.content1.desc,
        details: `<p>${content.ddDevelopment.content1.desc}</p>`,
        btnText: "Continue Reading",
      },
      {
        id: 2,
        icon: "resort.png",
        image: "service_details_1.jpg",
        title: content.ddDevelopment.content2.title,
        excerpt: content.ddDevelopment.content2.desc,
        details: `<p>${content.ddDevelopment.content2.desc}</p>`,
        btnText: "Continue Reading",
      },
      {
        id: 3,
        icon: "building.png",
        image: "service_details_1.jpg",
        title: content.ddDevelopment.content3.title,
        excerpt: content.ddDevelopment.content3.desc,
        details: `<p>${content.ddDevelopment.content3.desc}</p>`,
        btnText: "Continue Reading",
      },
    ];
  }, [content]);

  return (
    <>
      {/* Service Start */}
      <div
        id="dd-development"
        className="why-choose why-choos-bg"
        style={{ backgroundImage: `url(/images/development.png)` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6" />
            <div className="col-md-6 why-choose-us-before">
              <div className="row">
                <SectionTitle title="DNAR <span>Developement</span>" />
                {serviceData.map((item) => {
                  return (
                    <div className="col-md-6 col-sm-6">
                      <div className="why-choose-item">
                        <div className="why-choose-icon"></div>
                        <div className="media-body">
                          <h2>{item.title}</h2>
                          <p>{item.excerpt}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Service End */}
    </>
  );
};

export default DDDevelopment;
