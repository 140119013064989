import BaseHelmet from "@/common/BaseHelmet";
import Layout from "../../common/Layout";
import Breadcrumb from "../../common/breadcrumb/Breadcrumb";
import servicesData from "../../components/services/services-one/ServicesOneData.json";
import ServicesTypeOne from "../../components/services/services-one/ServicesTypeOne";

const ServicesOne = () => {
  return (
    <>
      <BaseHelmet page="Page" />
      <Layout>
        <Breadcrumb
          pageTitle="Service Page 1"
          rootPath="/"
          parentPath="Home"
          currentPath="Service 1"
        />
        {/* Service Start */}
        <div className="service-sec pt-100 pb-100">
          <div className="container">
            <div className="row">
              {servicesData.map((item) => (
                <ServicesTypeOne key={item.id} data={item} />
              ))}
            </div>
          </div>
        </div>
        {/* Service End */}
      </Layout>
    </>
  );
};

export default ServicesOne;
