import useLang from "@/hooks/useLang";
import SectionTitle from "../section-title/SectionTitle";
import WhyChooseUsCard from "../why-choose-us/WhyChooseUsCard";
// import ServicesTypeOne from "../services/services-one/ServicesTypeOne";
import { useMemo } from "react";

const DDBGServices = () => {
  const { content } = useLang();

  const contentData = useMemo(() => {
    return [
      {
        id: 1,
        icon: "customer.png",
        title: content.ddBg.content1.title,
        desc: content.ddBg.content1.desc,
      },
      {
        id: 2,
        icon: "employee.png",
        title: content.ddBg.content2.title,
        desc: content.ddBg.content2.desc,
      },
      {
        id: 3,
        icon: "locked.png",
        title: content.ddBg.content3.title,
        desc: content.ddBg.content3.desc,
      },
      {
        id: 4,
        icon: "lock.png",
        title: content.ddBg.content4.title,
        desc: content.ddBg.content4.desc,
      },
    ];
  }, [content]);

  return (
    <>
      {/* Service Start */}

      <div
        id="dd-bg"
        className="why-choose why-choos-bg"
        style={{ backgroundImage: `url(/images/why-choose-bg.jpg)` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6" />
            <div className="col-md-6 why-choose-us-before">
              <div className="row">
                <SectionTitle title={content.ddBg.title} />
                {contentData.slice(0, 4).map((item) => (
                  <WhyChooseUsCard key={item.id} data={item} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="service2-sec pt-100 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <SectionTitle
                title="What We <span>Offer</span> Here"
                content="<p>Lorem ipsum dolor sit amet, luctus posuere semper felis consectetuer hendrerit, enim varius enim, tellus tincidunt tellus est sed enim varius enim, tellus tincidunt tellus est sedLorem ipsum dolor sit amet, luctus posuere semper felis consectetuer hendrerit, enim varius enim, tellus tincidunt tellus est sed enim varius enim, tellus tincidunt tellus est sed</p>"
              />
            </div>
          </div>
          <div className="row">
            {data.map((item) => (
              <ServicesTypeOne key={item.id} data={item} />
            ))}
          </div>
        </div>
      </div> */}
      {/* Service End */}
    </>
  );
};

export default DDBGServices;
