import { Link } from "react-router-dom";
import { Menus } from "../responsive-menu/ResponsiveMenu";

const NavMenuItems = ({ menus }: { menus: Menus }) => {
  // const scrollToSection = useCallback((id: any) => {
  //   const element = document.getElementById(id);
  //   if (element) {
  //     window.scrollTo({
  //       top: element.offsetTop - 100, // Adjust for offset (e.g., fixed header)
  //       behavior: "smooth",
  //     });
  //   }
  // }, []);

  return (
    <>
      {menus.length > 0 &&
        menus.map((menu) => (
          <li key={menu.id}>
            {menu.link.startsWith("#") ? (
              <a href={menu.link} className="hover:!text-primary">
                {menu.text}{" "}
                {menu.dropdown.length > 0 && <i className="fa fa-angle-down" />}
              </a>
            ) : (
              <Link className="hover:!text-primary" to={menu.link}>
                {menu.text}{" "}
                {menu.dropdown.length > 0 && <i className="fa fa-angle-down" />}
              </Link>
            )}
            {menu.dropdown.length > 0 && (
              <ul>
                {menu.dropdown.map((dropdownMenu) => (
                  <li key={dropdownMenu.id}>
                    <Link
                      className="hover:!bg-primary-dark !text-text"
                      to={dropdownMenu.link}
                    >
                      {dropdownMenu.text}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
    </>
  );
};

export default NavMenuItems;
