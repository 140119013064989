import BaseHelmet from "@/common/BaseHelmet";
import Layout from "../../common/Layout";
import Breadcrumb from "../../common/breadcrumb/Breadcrumb";
import PostTypeOne from "../../components/post/PostTypeOne";
import getPostData from "../../components/post/PostTypeOneData.json";

const BlogOne = () => {
  return (
    <>
      <BaseHelmet page="Page" />
      <Layout>
        <Breadcrumb
          pageTitle="Blog Page 1"
          rootPath="/"
          parentPath="Home"
          currentPath="Blog Page 1"
        />
        {/* post Section Start */}
        <div className="blog-sec pt-100 pb-80">
          <div className="container">
            <div className="row">
              {getPostData.map((item) => (
                <PostTypeOne key={item.id} data={item} />
              ))}
            </div>
          </div>
        </div>
        {/* post Section End */}
      </Layout>
    </>
  );
};

export default BlogOne;
