import HomePageForm from "../quote-form/HomePageForm";

const Faq = () => {
  return (
    <>
      {/* Appointment Area Start*/}
      <div
        className="appoitment-area"
        // style={{ backgroundImage: `url(/images/testimonial_bg.jpg)` }}
      >
        <div className="images-overlay" />
        <div className="row">
          {/* <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="faq-sec">
                {getFaqData.map((item) => (
                  <FaqCard key={item.id} data={item} />
                ))}
              </div>
            </div> */}
          <div className="col-md-6 col-sm-6 col-xs-12 flex justify-center items-center">
            <iframe
              width="100%"
              height="100%"
              style={{
                border: 0,
              }}
              loading="lazy"
              allowFullScreen
              referrerPolicy="no-referrer-when-downgrade"
              src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3972.003755255858!2d100.335762!3d5.416396!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNcKwMjQnNTkuMCJOIDEwMMKwMjAnMDguNyJF!5e0!3m2!1sen!2smy!4v1732886298445!5m2!1sen!2smy"
            ></iframe>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-12">
            <HomePageForm />
          </div>
        </div>
      </div>
      {/* Appointment Area End */}
    </>
  );
};

export default Faq;
