import BaseHelmet from "../common/BaseHelmet";
import Layout from "../common/Layout";
import Breadcrumb from "../common/breadcrumb/Breadcrumb";
import ContactUsForm from "../components/contact-us-form/ContactUsForm";

const ContactInfo = [
  {
    title: "Phone",
    content: "04-2400955",
    // content: "0123456789<br />0987654321",
  },
  {
    title: "e-mail",
    content: "customerrelations@gmail.com",
    // content: "customerrelations@gmail.com<br />demomail@gmail.com",
  },
  {
    title: "address",
    content:
      "No 55-Tingkat 1, Lebuh Carnarvon. 10100, Georgetown. Pulau Pinang",
  },
];

const ContactUs = () => {
  return (
    <>
      <BaseHelmet page="Contact Us" />

      <Layout>
        <Breadcrumb
          pageTitle="Contact page"
          rootPath="/"
          parentPath="Home"
          currentPath="Contact Us"
        />
        {/* Contact Page Section Start */}
        <div className="contact-page-sec pt-100 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <div className="contact-field">
                  <h2>Write Your Message</h2>
                  <ContactUsForm />
                </div>
              </div>
              <div className="col-md-4">
                {ContactInfo.map((data, i) => (
                  <div key={i} className="contact-info">
                    <div className="contact-info-item">
                      <div className="contact-info-text">
                        <h2>{data.title}</h2>
                        <span
                          dangerouslySetInnerHTML={{ __html: data.content }}
                        />

                        {/*<span>{data.desc}</span>*/}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* Contact Page Section End */}
      </Layout>
    </>
  );
};

export default ContactUs;
