import DDBGServices from "@/components/home/DDBGServices";
import DDDevelopment from "@/components/home/DDDevelopment";
import DDSolutions from "@/components/home/DDSolutions";
import DDTraining from "@/components/home/DDTraining";
import BaseHelmet from "../common/BaseHelmet";
import Layout from "../common/Layout";
import AboutUs from "../components/about-us/AboutUs";
import Faq from "../components/faq/Faq";
import HeroSlider from "../components/hero-slider/HeroSlider";
import HomePost from "../components/home/HomePost";

const Home = () => {
  return (
    <>
      <BaseHelmet page="Home" />
      <Layout>
        <HeroSlider />
        <AboutUs />
        <DDSolutions />
        <DDTraining />
        <DDBGServices />
        <DDDevelopment />
        <HomePost />
        <Faq />
        {/*
          1.⁠ ⁠DNAR Bodyguard & Security Services.(Self explanatory)
          2.⁠ ⁠DNAR Solutions - Channel Partners for  companies in assisting for sofware purchases.
          3.⁠ ⁠DNAR Development - Property Development, Construction, Management & Sales.
          4.⁠ ⁠DNAR Training Services - Provides safety,self improvement, security, bodyguard and many more training services.
        */}
        {/* <WhyChooseUs /> */}
        {/* <ProjectsTypeOne /> */}
        {/* <Testimonial /> */}
      </Layout>
    </>
  );
};

export default Home;
