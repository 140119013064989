import useLang from "@/hooks/useLang";
import { useMemo } from "react";
import SectionTitle from "../section-title/SectionTitle";
import ModifiedServicesTypeOne from "../services/services-one/ModifiedServicesTypeOne";

const DDSolutions = () => {
  const { content } = useLang();

  const serviceData = useMemo(() => {
    return [
      {
        id: 1,
        icon: "resort.png",
        image: "service_details_1.jpg",
        title: content.ddSolutions.content1.title,
        excerpt: content.ddSolutions.content1.desc,
        details: `<p>${content.ddSolutions.content1.desc}</p>`,
        btnText: "Continue Reading",
      },
      {
        id: 2,
        icon: "resort.png",
        image: "service_details_1.jpg",
        title: content.ddSolutions.content2.title,
        excerpt: content.ddSolutions.content2.desc,
        details: `<p>${content.ddSolutions.content2.desc}</p>`,
        btnText: "Continue Reading",
      },
      {
        id: 3,
        icon: "building.png",
        image: "service_details_1.jpg",
        title: content.ddSolutions.content3.title,
        excerpt: content.ddSolutions.content3.desc,
        details: `<p>${content.ddSolutions.content3.desc}</p>`,
        btnText: "Continue Reading",
      },
      {
        id: 4,
        icon: "building.png",
        image: "service_details_1.jpg",
        title: content.ddSolutions.content4.title,
        excerpt: content.ddSolutions.content4.desc,
        details: `<p>${content.ddSolutions.content4.desc}</p>`,
        btnText: "Continue Reading",
      },
    ];
  }, [content]);

  return (
    <>
      {/* Service Start */}
      <div
        id="dd-solutions"
        className="service2-sec pt-100 pb-100"
        style={{ backgroundImage: `url(/images/slider/technology.jpg)` }}
      >
        <div className="absolute h-full w-full top-0 bg-black bg-opacity-50"></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <SectionTitle
                title={content.ddSolutions.title}
                content={`<p>${content.ddSolutions.desc}</p>`}
              />
            </div>
          </div>
          <div className="row">
            {serviceData.map((item) => (
              <ModifiedServicesTypeOne key={item.id} data={item} />
            ))}
          </div>
        </div>
      </div>
      {/* Service End */}
    </>
  );
};

export default DDSolutions;
