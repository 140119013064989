import BaseHelmet from "@/common/BaseHelmet";
import Layout from "../../common/Layout";
import Breadcrumb from "../../common/breadcrumb/Breadcrumb";
import servicesData from "../../components/services/services-two/ServicesTwoData.json";
import ServicesTypeTwo from "../../components/services/services-two/ServicesTypeTwo";

const ServicesTwo = () => {
  return (
    <>
      <BaseHelmet page="Page" />
      <Layout>
        <Breadcrumb
          pageTitle="Service Page 2"
          rootPath="/"
          parentPath="Home"
          currentPath="Service 2"
        />
        {/* Service Start */}
        <div className="service-sec pt-100 pb-100">
          <div className="container">
            <div className="row">
              {servicesData.map((item) => (
                <ServicesTypeTwo key={item.id} data={item} />
              ))}
            </div>
          </div>
        </div>
        {/* Service End */}
      </Layout>
    </>
  );
};

export default ServicesTwo;
