import useLang from "@/hooks/useLang";
import { useMemo } from "react";
import Slider from "react-slick";
import "../../assets/css/slick-theme.css";
import "../../assets/css/slick.css";
import { HeroSliderConfig } from "../../includes/scripts";

const HeroSlider = () => {
  const { content } = useLang();

  const sliderData = useMemo(() => {
    return [
      {
        id: 1,
        image: "about.jpg",
        title: content.hero.title,
        content: `${content.hero.desc} [Context Writing Placeholder : Lorem ipsum dolor sit amet, ligula magna at etiam aliquip venenatis.]`,
        btnTextOne: "CONTACT US",
        btnLinkOne: "/contact-us",
        btnTextTwo: content.hero.cta1,
        btnLinkTwo: "#dd-bg",
      },
      {
        id: 2,
        image: "technology.jpg",
        title: content.hero.title,
        content: `${content.hero.desc} [Context Writing Placeholder : Lorem ipsum dolor sit amet, ligula magna at etiam aliquip venenatis.]`,
        btnTextOne: "CONTACT US",
        btnLinkOne: "/contact-us",
        btnTextTwo: content.hero.cta2,
        btnLinkTwo: "#dd-solutions",
      },
    ];
  }, [content]);

  return (
    <>
      {/* Slider Section Start */}
      <div className="slider index2">
        <div className="all-slide">
          {/*>*/}
          <Slider {...HeroSliderConfig}>
            {sliderData.map((data, i) => (
              <div key={i}>
                <div
                  className="single-slide"
                  style={{
                    backgroundImage: `url(/images/slider/${data.image})`,
                  }}
                >
                  <div className="slider-overlay" />
                  <div className="slider-text">
                    <div className="slider-wraper">
                      <h1 dangerouslySetInnerHTML={{ __html: data.title }}></h1>
                      <p>{data.content}</p>
                      <ul>
                        <li>
                          <a href={data.btnLinkOne}>{data.btnTextOne}</a>
                        </li>
                        <li>
                          <a
                            className="whitespace-nowrap hover:text-black"
                            href={data.btnLinkTwo}
                          >
                            {data.btnTextTwo}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
          {/*</OwlCarousel>*/}
        </div>
      </div>
      {/* Slider Section End */}
    </>
  );
};

export default HeroSlider;
