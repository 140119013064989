import BaseHelmet from "@/common/BaseHelmet";
import { useParams } from "react-router-dom";
import Layout from "../../common/Layout";
import Breadcrumb from "../../common/breadcrumb/Breadcrumb";
import getProjectData from "../../components/projects/ProjectsData.json";

const ProjectDetails = () => {
  const { id } = useParams();
  const projectData = getProjectData.filter(
    (project) => project.id === parseInt(id)
  )[0];

  return (
    <>
      <BaseHelmet page="Page" />

      <Layout>
        <Breadcrumb
          pageTitle={projectData.title}
          rootPath="/"
          parentPath="Home"
          currentPath={projectData.title}
        />
        {/* Project Details Section Start */}
        <div className="about-us-sec pt-100 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="abt-img">
                  <img
                    src={`/images/projects/${projectData.image}`}
                    alt={projectData.title}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="abt-lft">
                  <h2>{projectData.title}</h2>
                  <div
                    dangerouslySetInnerHTML={{ __html: projectData.details }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Project Details Section End */}
      </Layout>
    </>
  );
};

export default ProjectDetails;
