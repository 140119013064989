import content from "@/config/content";
import { useLocation } from "react-router-dom";

const useLang = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const lang = query.get("lang");

  return {
    lang: lang?.toUpperCase(),
    content: lang?.toUpperCase() === "BM" ? content["BM"] : content["EN"],
  };
};

export default useLang;
