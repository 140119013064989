import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";
import NavMenuItems from "../header/NavMenuItems";

export interface Menu {
  id: number;
  text: string;
  link: string;
  dropdown: any[];
}

export type Menus = Menu[];

const ResponsiveMenu = ({ menus }: { menus: Menus }) => {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => {
    setSidebar(!sidebar);
  };

  return (
    <>
      <Link to="#" className="menu-bars">
        <FaBars onClick={showSidebar} />
      </Link>
      <nav>
        <ul className={sidebar ? "mobile-nav-menu active" : "mobile-nav-menu"}>
          <li className="navbar-close">
            <Link to="#" className="menu-close-bars">
              <AiOutlineClose onClick={showSidebar} />
            </Link>
          </li>
          <NavMenuItems menus={menus} />
        </ul>
      </nav>
    </>
  );
};

export default ResponsiveMenu;
