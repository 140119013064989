import BaseHelmet from "@/common/BaseHelmet";
import Layout from "../../common/Layout";
import Breadcrumb from "../../common/breadcrumb/Breadcrumb";
import BlogSidebar from "../../components/blog-sidebar/BlogSidebar";
import PostTypeTwo from "../../components/post/PostTypeTwo";
import getPostData from "../../components/post/PostTypeTwoData.json";

const BlogTwo = () => {
  return (
    <>
      <BaseHelmet page="Page" />
      <Layout>
        <Breadcrumb
          pageTitle="Blog Page 2"
          rootPath="/"
          parentPath="Home"
          currentPath="Blog Page 2"
        />
        {/* Blog Section Start */}
        <div className="blog-classic-sec pt-100 pb-70">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                {getPostData.map((item) => (
                  <PostTypeTwo key={item.id} data={item} />
                ))}
              </div>
              <BlogSidebar />
            </div>
          </div>
        </div>
        {/* Blog Section End */}
      </Layout>
    </>
  );
};

export default BlogTwo;
