import useLang from "@/hooks/useLang";
import { useMemo } from "react";
import ModifiedPostTypeOne from "../post/ModifiedPostTypeOne";
import SectionTitle from "../section-title/SectionTitle";

const HomePost = () => {
  const { content } = useLang();

  const serviceData = useMemo(() => {
    return [
      {
        id: 1,
        image: "g1.jpg",
        title: content.news.content1.title,
        details:
          "<p>Id detracto luptatum qui, ne mel alia facilis. Pri id mazim percipit, vis scripta veritus in, facete dissentiunt ad mei. Ex eum admodum democritum, cu per malis ancillae deterruisset. Eum ornatus minimum delicata in, mea at recusabo oportere.Sea inermis maiestatis adversarium ex, quaeque dolorum id eam. Debet omnes noster ei nam. Ex mei dolorum expetenda, ad prompta rationibus duo. Brute mandamus mediocrem eum ad, has alterum explicari definitiones no.</p><p>Ex ludus efficiendi mel, sed id esse latine verterem. Nam affert graece alienum at. Cetero neglegentur at duo, ut est augue falli quando. Sed eruditi accumsan inciderint et, at eam erat aliquam lobortis. Cu commune mediocritatem mei. Qui id altera laoreet gloriatur, case debet et sea, sed sumo vivendum cu. </p>",
        content:
          "Lorem ipsum dolor sit amet, vitae mattis vehicula scelerisque suscipit donec, tortor duis phasellus vivamus wisi",
        btnText: "Read More",
        btnLink: "/",
        date: "24 May 2017",
        categories: "Office Security",
      },
      {
        id: 2,
        image: "g2.jpg",
        title: content.news.content2.title,
        details:
          "<p>Id detracto luptatum qui, ne mel alia facilis. Pri id mazim percipit, vis scripta veritus in, facete dissentiunt ad mei. Ex eum admodum democritum, cu per malis ancillae deterruisset. Eum ornatus minimum delicata in, mea at recusabo oportere.Sea inermis maiestatis adversarium ex, quaeque dolorum id eam. Debet omnes noster ei nam. Ex mei dolorum expetenda, ad prompta rationibus duo. Brute mandamus mediocrem eum ad, has alterum explicari definitiones no.</p><p>Ex ludus efficiendi mel, sed id esse latine verterem. Nam affert graece alienum at. Cetero neglegentur at duo, ut est augue falli quando. Sed eruditi accumsan inciderint et, at eam erat aliquam lobortis. Cu commune mediocritatem mei. Qui id altera laoreet gloriatur, case debet et sea, sed sumo vivendum cu. </p>",
        content:
          "Lorem ipsum dolor sit amet, vitae mattis vehicula scelerisque suscipit donec, tortor duis phasellus vivamus wisi",
        btnText: "Read More",
        btnLink: "/",
        date: "24 May 2017",
        categories: "News",
      },
      {
        id: 3,
        image: "g3.jpg",
        title: content.news.content3.title,
        details:
          "<p>Id detracto luptatum qui, ne mel alia facilis. Pri id mazim percipit, vis scripta veritus in, facete dissentiunt ad mei. Ex eum admodum democritum, cu per malis ancillae deterruisset. Eum ornatus minimum delicata in, mea at recusabo oportere.Sea inermis maiestatis adversarium ex, quaeque dolorum id eam. Debet omnes noster ei nam. Ex mei dolorum expetenda, ad prompta rationibus duo. Brute mandamus mediocrem eum ad, has alterum explicari definitiones no.</p><p>Ex ludus efficiendi mel, sed id esse latine verterem. Nam affert graece alienum at. Cetero neglegentur at duo, ut est augue falli quando. Sed eruditi accumsan inciderint et, at eam erat aliquam lobortis. Cu commune mediocritatem mei. Qui id altera laoreet gloriatur, case debet et sea, sed sumo vivendum cu. </p>",
        content:
          "Lorem ipsum dolor sit amet, vitae mattis vehicula scelerisque suscipit donec, tortor duis phasellus vivamus wisi",
        btnText: "Read More",
        btnLink: "/",
        date: "24 May 2017",
        categories: "CCTV Security",
      },
    ];
  }, [content]);

  return (
    <>
      {/* post Section Start */}
      <div className="blog-sec pt-100 pb-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <SectionTitle
                title={content.news.title}
                content={`<p>${content.news.desc}</p>`}
              />
            </div>
          </div>
          <div className="row">
            {serviceData.map((item) => (
              <ModifiedPostTypeOne key={item.id} data={item} />
            ))}
          </div>
        </div>
      </div>
      {/* post Section End */}
    </>
  );
};

export default HomePost;
