import useLang from "@/hooks/useLang";
import SectionTitle from "../section-title/SectionTitle";

const AboutUs = () => {
  const { content } = useLang();

  return (
    <>
      {/* About Start */}
      <div id="about-us" className="about-sec pt-100 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="about-desc">
                <SectionTitle
                  title={content.about.title}
                  content={`<p>${content.about.desc}</p>`}
                />
                <div></div>
              </div>
            </div>

            <div className="flex justify-between gap-10">
              {/* <div>
                <div className="flex justify-center items-center gap-5">
                  <GiStaticGuard className="w-10 h-10" />
                  <div className="font-bold text-2xl">DD BG</div>
                </div>
                <div>
                  <p>[placeholder]</p>
                </div>
              </div>
              <div>
                <div className="flex justify-center items-center gap-5">
                  <GiCheckMark className="w-10 h-10" />
                  <div className="font-bold text-2xl">DD Solutions</div>
                </div>
                <div>
                  <p>[placeholder]</p>
                </div>
              </div> */}
            </div>
            {/* <div className="col-md-5">
              <div className="about-us-img">
                <img src={`/images/about.jpg`} alt="About Thumb" />
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* About End */}
    </>
  );
};

export default AboutUs;

{
  /* <div className="about-countup">
    {CounterItem.map((data, i) => (
    <div key={i} className="counting-inner">
        <div className="countup-text">
        <h2 className="counter">
            <CountUp
            end={data.number}
            duration={2.5}
            delay={0.9}
            start={0}
            />
        </h2>
        <h4>{data.title}</h4>
        </div>
    </div>
    ))}
</div> */
}
