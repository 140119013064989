import BaseHelmet from "@/common/BaseHelmet";
import Breadcrumb from "../../common/breadcrumb/Breadcrumb";
import Layout from "../../common/Layout";
import ProjectTypesOneCard from "../../components/projects/projects-one/ProjectTypesOneCard";
import getProjectData from "../../components/projects/ProjectsData.json";

const ProjectsTwo = () => {
  return (
    <>
      <BaseHelmet page="Page" />
      <Layout>
        <Breadcrumb
          pageTitle="Projects 4 column Gallery"
          rootPath="/"
          parentPath="Home"
          currentPath="Projects 2"
        />
        {/* Project Section Start */}
        <div className="project-sec pt-100 pb-70">
          <div className="container">
            <div className="row">
              {getProjectData.map((item) => (
                <ProjectTypesOneCard
                  key={item.id}
                  data={item}
                  extraClass={"col-xs-6 col-sm-4 col-md-3"}
                />
              ))}
            </div>
          </div>
        </div>
        {/* Project Section Start */}
      </Layout>
    </>
  );
};

export default ProjectsTwo;
