import BaseHelmet from "../common/BaseHelmet";
import Layout from "../common/Layout";
import Breadcrumb from "../common/breadcrumb/Breadcrumb";
import AboutUs from "../components/about-us/AboutUs";
import ClientLogo from "../components/client-logo/ClientLogo";
import TeamMembers from "../components/team-members/TeamMembers";
import Testimonial from "../components/testimonial/Testimonial";

const AboutUsPage = () => {
  return (
    <>
      <BaseHelmet page="About Us" />
      <Layout>
        <Breadcrumb
          pageTitle="About Us Page"
          rootPath="/"
          parentPath="Home"
          currentPath="About Us"
        />
        <AboutUs />
        <Testimonial />
        <TeamMembers />
        <ClientLogo />
      </Layout>
    </>
  );
};

export default AboutUsPage;
