import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import ScrollToTop from "./common/ScrollToTop";
import AboutUsPage from "./pages/AboutUsPage";
import BlogOne from "./pages/blogs/BlogOne";
import BlogTwo from "./pages/blogs/BlogTwo";
import BlogWithSidebar from "./pages/blogs/BlogWithSidebar";
import ContactUs from "./pages/ContactUs";
import BlogDetails from "./pages/details-page/BlogDetails";
import ProjectDetails from "./pages/details-page/ProjectDetails";
import ServiceDetailsOne from "./pages/details-page/service-details/ServiceDetailsOne";
import ServiceDetailsTwo from "./pages/details-page/service-details/ServiceDetailsTwo";
import Home from "./pages/Home";
import ProjectsOne from "./pages/projects/ProjectsOne";
import ProjectsTwo from "./pages/projects/ProjectsTwo";
import ServicesOne from "./pages/services/ServicesOne";
import ServicesTwo from "./pages/services/ServicesTwo";
import TeamMembersPage from "./pages/TeamMembersPage";

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path={"/services-1"} element={<ServicesOne />} />
          <Route path={"/services-2"} element={<ServicesTwo />} />
          <Route
            path={"/service-details/:id"}
            element={<ServiceDetailsOne />}
          />
          <Route
            path={"/service-details-two/:id"}
            element={<ServiceDetailsTwo />}
          />
          <Route path={"/projects-1"} element={<ProjectsOne />} />
          <Route path={"/projects-2"} element={<ProjectsTwo />} />
          <Route path={"/project-details/:id"} element={<ProjectDetails />} />
          <Route path={"/blogs-1"} element={<BlogOne />} />
          <Route path={"/blogs-2"} element={<BlogTwo />} />
          <Route path={"/blog-with-sidebar"} element={<BlogWithSidebar />} />
          <Route path={"/blog-details/:id"} element={<BlogDetails />} />
          <Route path={"/employees"} element={<TeamMembersPage />} />
          <Route path={"/contact-us"} element={<ContactUs />} />
          <Route path={"/about"} element={<AboutUsPage />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
