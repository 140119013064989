import useLang from "@/hooks/useLang";
import cn from "classnames";

const Topbar = () => {
  const { lang, content } = useLang();

  return (
    <>
      {/* header Top Start */}
      <div className="hd-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-sm-8">
              <div className="hd-lft">
                <ul>
                  <li>
                    <a className="text-white" href="/contact-us">
                      <i className="fa fa-comment" />
                      {content.commonText["contact"]}
                    </a>
                  </li>
                  <li>
                    <a className="text-white">
                      <i className="fa fa-phone" />
                      04-2400955
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="hd-rgt">
                <ul className="pr-3">
                  <li>
                    <a
                      className={cn("", {
                        ["!text-primary-500"]:
                          lang === "EN" || lang === undefined,
                      })}
                      href="?lang=EN"
                    >
                      EN
                    </a>
                  </li>
                  <li className="text-neutral-300">
                    <div className="pl-2">|</div>
                  </li>
                  <li>
                    <a
                      className={cn("", {
                        ["!text-primary-500"]: lang === "BM",
                      })}
                      href="?lang=BM"
                    >
                      BM
                    </a>
                  </li>
                </ul>
                <span className="!text-white">
                  {content.commonText["follow-us"]}
                </span>
                <ul>
                  <li>
                    <a href="#">
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-instagram" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* header Top End */}
    </>
  );
};

export default Topbar;
