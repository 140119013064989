import useLang from "@/hooks/useLang";
import { Link } from "react-router-dom";

const Footer = () => {
  const { content } = useLang();

  return (
    <>
      {/* footer Section Start */}
      <footer>
        {/* footer Top Section Start */}
        <div className="footer-sec">
          <div className="container">
            <div className="flex flex-col md:flex-row flex-wrap justify-between gap-5">
              <div className="flex-1 lg:flex-1 basis-0">
                <div className="footer-wedget-one">
                  <Link to="/">
                    <img src={`/logo.png`} alt="Logo" />
                  </Link>
                  <div className="flex flex-col gap-0 text-white">
                    <div>{content.footer.slogan}</div>
                  </div>
                  <div className="footer-social-profile">
                    <ul>
                      <li>
                        <a href="">
                          <i className="fa fa-facebook" />
                        </a>
                      </li>
                      <li>
                        <a href="">
                          <i className="fa fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="">
                          <i className="fa fa-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="flex-1 lg:flex-1 basis-0">
                <div className="footer-widget-menu">
                  <h2>{content.commonText["link-services"]} </h2>
                  <ul>
                    <li>
                      <a href="#dd-bg">DD Solutions</a>
                    </li>
                    <li>
                      <a href="#dd-bg">DD Training Services</a>
                    </li>
                    <li>
                      <a href="#dd-bg">DD Bodyguard & Security Services</a>
                    </li>
                    <li>
                      <a href="#dd-bg">DD Development</a>
                    </li>
                    <li>
                      <a href="/contact-us">{content.commonText.contact}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex-1 lg:flex-1 basis-0">
                <div className="footer-wedget-four">
                  <h2>{content.commonText.contact} </h2>
                  <div className="inner-box">
                    <div className="inner-item">
                      <div className="media">
                        <div className="media-left">
                          <span className="icon">
                            <i className="fa fa-map-marker" />
                          </span>
                        </div>
                        <div className="media-body">
                          <span className="inner-text">
                            No 55-Tingkat 1, Lebuh Carnarvon. 10100, Georgetown.
                            Pulau Pinang
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="inner-item">
                      <div className="media">
                        <div className="media-left">
                          <span className="icon">
                            <i className="fa fa-envelope-o" />
                          </span>
                        </div>
                        <div className="media-body">
                          <div>customerrelations@dnardynasty.com</div>
                        </div>
                      </div>
                    </div>
                    <div className="inner-item">
                      <div className="media">
                        <div className="media-left">
                          <span className="icon">
                            <i className="fa fa-phone" />
                          </span>
                        </div>
                        <div className="media-body">
                          <span className="inner-text">
                            04-2400955
                            {/* +880 256794, 24-2564687 */}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* footer Top Section End */}
        {/* footer Bottom Section Start */}
        <div className="footer-bottom-sec">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="copy-right">
                  <p>
                    © Copyright 2024{" "}
                    <span>
                      <a href="#">DNAR DYNASTY</a>
                    </span>{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* footer Bottom Section End */}
      </footer>
      {/* footer Section End */}
    </>
  );
};

export default Footer;
