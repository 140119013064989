import useLang from "@/hooks/useLang";
import { useMemo } from "react";
import SectionTitle from "../section-title/SectionTitle";
import ModifiedServicesTypeOne from "../services/services-one/ModifiedServicesTypeOne";

const DDTraining = () => {
  const { content } = useLang();

  const serviceData = useMemo(() => {
    return [
      {
        id: 1,
        title: content.ddTraining.content1.title,
        icon: "building.png",
        image: "service_details_1.jpg",
        excerpt: content.ddTraining.content1.desc,
        details: `<p>${content.ddTraining.content1.desc}</p>`,
        btnText: "Continue Reading",
      },
      {
        id: 2,
        title: content.ddTraining.content2.title,
        icon: "surveillance.png",
        image: "service_details_1.jpg",
        excerpt: content.ddTraining.content2.desc,
        details: `<p>${content.ddTraining.content2.desc}</p>`,
        btnText: "Continue Reading",
      },
      {
        id: 3,
        title: content.ddTraining.content3.title,
        icon: "resort.png",
        image: "service_details_1.jpg",
        excerpt: content.ddTraining.content3.desc,
        details: `<p>${content.ddTraining.content3.desc}</p>`,
        btnText: "Continue Reading",
      },
      {
        id: 4,
        title: content.ddTraining.content4.title,
        icon: "resort.png",
        image: "service_details_1.jpg",
        excerpt: content.ddTraining.content4.desc,
        details: `<p>${content.ddTraining.content4.desc}</p>`,
        btnText: "Continue Reading",
      },
    ];
  }, [content]);

  return (
    <>
      {/* Service Start */}
      <div id="dd-training" className="service2-sec pt-100 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <SectionTitle
                title={content.ddTraining.title}
                content={`<p>${content.ddTraining.desc}</p>`}
              />
            </div>
          </div>
          <div className="row">
            {serviceData.map((item) => (
              <ModifiedServicesTypeOne key={item.id} data={item} />
            ))}
          </div>
        </div>
      </div>
      {/* Service End */}
    </>
  );
};

export default DDTraining;
