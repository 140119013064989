import BaseHelmet from "../common/BaseHelmet";
import Breadcrumb from "../common/breadcrumb/Breadcrumb";
import Layout from "../common/Layout";
import TeamMembersCard from "../components/team-members/TeamMembersCard";
import getTeamMembersData from "../components/team-members/TeamMembersCardData.json";

const TeamMembersPage = () => {
  const data = getTeamMembersData.slice(0, 16);

  return (
    <>
      <BaseHelmet page="Page" />

      <Layout>
        <Breadcrumb
          pageTitle="Our Employees"
          rootPath="/"
          parentPath="Home"
          currentPath="Our Employees"
        />
        {/* Team Section Start */}
        <div className="team-sec pt-100 pb-70">
          <div className="container">
            <div className="row">
              {data.map((item) => (
                <TeamMembersCard key={item.id} data={item} />
              ))}
            </div>
          </div>
        </div>
        {/* Team Section End */}
      </Layout>
    </>
  );
};

export default TeamMembersPage;
