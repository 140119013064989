const SectionTitle = ({ title, content }: any) => {
  return (
    <>
      <div className="sec-title">
        <h1 dangerouslySetInnerHTML={{ __html: title }} />
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </>
  );
};

export default SectionTitle;
